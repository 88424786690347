/**
 * Foundation init
 */
$(document).foundation();

/**
 * Callout
 */
$.fn.callout = function(message, type) {
    return $(this).html('<div class="' + type + '">' + message + '</div>');
};

/**
 * Equalizer
 */
$.fn.equalizer = function() {
    $(this).css('height', 'auto');

    var elementHeights = $(this).map(function() {
        return $(this).outerHeight();
    }).get();

    var maxHeight = Math.max.apply(null, elementHeights);

    $(this).css('height', maxHeight);
};

/**
 * Contact form
 */
$('form.contact').on('submit', function(event) {
    event.preventDefault();

    // Used varables
    var self = this;
    var response = $(this).find('.response');

    // Alert classes
    var success_class = "callout success";
    var error_class = "callout alert";
    var loading_class = "callout primary";
    var button = $(this).find('.button');

    // Add loader
    button.attr('data-loading', '');
    response.html('');

    // Ajax submit
    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajax_url + 'contact',
        data: $(self).serialize(),

        // If success
        success: function(data){

            // Remove loader
            button.removeAttr('data-loading');

            // Response
            if (data.success) {

                // Set success
                response.callout(data.message, success_class);

                // Clear fields
                $(self).find('input, textarea').val('');
            } else {

                // Set error
                response.callout(data.message, error_class);
            };
        }

    });
});

/**
 * Load & resize
 */
$(window).on('resize', function () {
    Foundation.reInit('equalizer');
});

$(window).on('resize load', function () {
    $('.hoffer__loop .columns').equalizer();
    $('.sblock__details').equalizer();
});

/**
 * Partners
 */
 $('.partners__loop').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

/**
 * Slides
 */
$('.slides__loop').slick({
    arrows: false,
    asNavFor: '.slides__backgrounds',
});

$('.slides__backgrounds').slick({
    arrows: false,
    arrows: false,
    fade: true,
    asNavFor: '.slides__loop'
});

/**
 * Fancybox
 */
$(document).ready(function() {
    $('.fancybox-media').fancybox({
        helpers : {
            media : {},
            overlay: {
                locked: false
            }
        }
    });
});

$(document).on('click', '.slides__tip', function () {
    $('.slides__play').click();
});

$(document).on('click', '.dropdown', function () {
    $(this).parent('li').toggleClass('show');
});

/**
 * Services
 */
$('.sblock__loop').slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

/**
* Menu
*/
$(window).on('load resize', function () {
    var nav = $('.menu__items');
    var item = nav.find('li:first-child');

    nav.removeClass('mobile');

    if (nav.outerHeight() > item.outerHeight()) {
        nav.addClass('mobile');
    }

    $('.header__blocks li').removeClass('active');
}).resize();

/**
* Open
*/
$('.burger').on('click', function () {
    $(this).toggleClass('open');
    $('.menu__items').toggleClass('menu__items--show');
});

/**
* Open
*/
$(document).on('click', '.header__blocks li', function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
    } else {
        $('.header__blocks li').removeClass('active');
        $(this).addClass('active');
    }
});
